import { useQuery } from '@tanstack/react-query';
import { IResourcePlannerTotalResult } from 'src/apis/types/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { postFetch } from 'src/utils/fetcher';
import { getFiltersParam, getQueryParams } from '../utils';
import { GROUP_BY_RESOURCE_TOTAL_KEY } from './getGroupByResourceTotalAPI.constants';

const GROUP_BY_RESOURCE_TOTAL_URL_PATH = '/api/v2/resource-planner/group-by-employee-total-row';

const resourcePlannerFetch = (
  filters: string,
  params: Record<string, string>,
): Promise<IResourcePlannerTotalResult> =>
  postFetch({
    path: `${GROUP_BY_RESOURCE_TOTAL_URL_PATH}${getQueryParams(params)}`.concat(filters),
    key: GROUP_BY_RESOURCE_TOTAL_KEY,
  });

interface IUseGetGroupByResourceTotal {
  params: Record<string, string>;
  selectedFilterList: IFilterStoreProps['selectedFilterList'];
}

export const useGetGroupByResourceTotal = ({
  params,
  selectedFilterList,
}: IUseGetGroupByResourceTotal) => {
  const filtersParam = getFiltersParam(selectedFilterList);

  const { data, isError, ...restProps } = useQuery({
    queryFn: () => resourcePlannerFetch(filtersParam, params),
    queryKey: [GROUP_BY_RESOURCE_TOTAL_KEY, params, selectedFilterList],
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    data,
    isError,
    ...restProps,
  };
};
