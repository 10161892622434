import { useMutation, useQueryClient } from '@tanstack/react-query';
import { reloadRpTables } from 'src/screens/ResourcePlanner/helper/reloadRpTables';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';

const POST_IMPORT_EMPLOYEES_URL_PATH = '/api/v2/resource-planner/start-initial-send-of-employees';
export const POST_IMPORT_EMPLOYEES_MSW_STATUS_KEY = 'PostImportAllEmployees';

export default () => {
  const path = formatUrl(POST_IMPORT_EMPLOYEES_URL_PATH);
  const importAllEmployees = () =>
    postFetch({
      path,
      key: POST_IMPORT_EMPLOYEES_MSW_STATUS_KEY,
    });
  const qc = useQueryClient();

  return useMutation(importAllEmployees, {
    onSuccess: () => {
      reloadRpTables(qc);
    },
  });
};
